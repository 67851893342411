.QR-Container{
  background-color: white;
  padding: 1vh;
  border-radius: 1vh;
}
.gradient-border {
  position: relative;
  --border-width: 3px;
  border-radius: var(--border-width);
  z-index: 1;
}
.gradient-border::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 5s infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.App {
  text-align: center;
}
.blob-text {
    padding: 4px;
    max-width: 40rem;
  }
@media (max-width: 800px) {
  .blob-text {
    padding: 4px;
    max-width: 20rem;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.avatar {
  height: 100%;
  position: relative;
  width: 100%;
}

.avatar img {
  border-radius: 9999px;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
  max-width:150px;
  max-height:150px;
}

@keyframes cycle-colors {
  0% { border-color: hsl(0, 100%, 50%); }
  25% { border-color: hsl(90, 100%, 50%); }
  50% { border-color: hsl(180, 100%, 50%); }
  75% { border-color: hsl(270, 100%, 50%); }
  100% { border-color: hsl(360, 100%, 50%); }
}

@keyframes pulse {
  to {
    opacity: 0;
    transform: scale(1);
  }
}

.avatar::before,.avatar::after {
  animation: pulse 2s alternate infinite;
  border: green solid 8px;
  border-radius: 9999px;
  box-sizing: border-box;
  content: ' ';
  height: 140%;
  left: -20%;
  opacity: .6;
  position: absolute;
  top: -20%;
  transform: scale(0.8);
  width: 140%;
  z-index: 1;
}

.avatar::after {
  animation-delay: 0.5s;
}

.waiting-image{
  display: inline-block;
}
